import React, { Component } from 'react';
 
import { withFirebase } from '../Firebase';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


const PasswordChangePage = () => (
    <div>
      <PasswordChangeForm />
    </div>
  );

const styles = theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  });
 
const INITIAL_STATE = {
passwordOne: '',
passwordTwo: '',
error: null,
};
 
class PasswordChangeBase extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { passwordOne } = this.state;
 
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { passwordOne, passwordTwo, error } = this.state;
 
    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    const { classes } = this.props;
 
    return (
    <Container style={{paddingBottom: '20px'}} component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <Typography component="h1" variant="h5">
                Change Password
            </Typography>
            <br/>
            <form className={classes.form} onSubmit={this.onSubmit}>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="passwordOne"
                        label="New Password"
                        type="password"
                        id="passwordOne"
                        value={passwordOne}
                        onChange={this.onChange}
                        autoComplete="current-password"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="passwordTwo"
                        label="Confirm New Password"
                        type="password"
                        id="passwordTwo"
                        value={passwordTwo}
                        onChange={this.onChange}
                        autoComplete="current-password"
                    />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isInvalid}
                >
                    Update Password
                </Button>
 
                {error && <p style={{color: 'red'}}>{error.message}</p>}
            </form>
        </div>
    </Container>
    );
  }
}

export default PasswordChangePage;
 
const PasswordChangeForm = withStyles(styles)(withFirebase(PasswordChangeBase));

export { PasswordChangeForm };