function preprocessSetup(setup) {
      
    // calculate additional values
    setup.notes.map((note, i) => {
      note.x = i+1
      note.color = setup.colorMap[note.register]
      note.size = Math.abs(note.intonation)
      if (note.note === 'C'){
        note.tickLabel = note.note + note.octave
      } else {
        note.tickLabel = note.note
      }
      
      return ""
    })
    setup.name = "My Setup"

    return setup
  }

const avg = arr => arr.reduce((a,b) => a + b, 0) / arr.length

const median = (arr) => {

  // cast to float
  arr = arr.map((x) => parseFloat(x))

  const mid = Math.floor(arr.length / 2),
  nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;

}

function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}


function getRegisterPairs(setup) {
  const registerPairs = setup.registerPairs;
  const notes = setup.notes;
  let outputPairs = [];

  registerPairs.forEach((registerPair, i) => {
    const lowerNote = registerPair[0].slice(0,registerPair[0].length-1);
    const lowerOctave = registerPair[0].charAt(registerPair[0].length-1);
    const upperNote = registerPair[1].slice(0,registerPair[1].length-1);
    const upperOctave = registerPair[1].charAt(registerPair[1].length-1);

    const lower = notes.filter(note => note.note == lowerNote & note.octave == lowerOctave);
    const upper = notes.filter(note => note.note == upperNote & note.octave == upperOctave);

    // get median of all alternate fingerings
    let lowerIntonations = [];
    lower.forEach(note => {
      lowerIntonations.push(note.intonation);
    });

    let upperIntonations = [];
    upper.forEach(note => {
      upperIntonations.push(note.intonation);
    })

    const twelthSize = 30;
    const lowerIntonation = median(lowerIntonations);
    const upperIntonation = Number(median(upperIntonations)) + Number(twelthSize);
    const spread = Number(upperIntonation) - Number(lowerIntonation) - Number(twelthSize);
    
    outputPairs.push({
      "x": i+1,
      "registerPair": [
        lowerIntonation,
        upperIntonation
      ],
      "lowerName": lowerNote + lowerOctave,
      "lowerIntonation": round(lowerIntonation, 1),
      "upperName": upperNote + upperOctave,
      "upperIntonation": round(upperIntonation, 1),
      "spread": round(spread, 1)
    });

  });

  return({
    'pairs': outputPairs,
    'avgSpread': round(avg(outputPairs.map(pair => Number(pair.spread))), 1)
  });
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export { preprocessSetup, getRegisterPairs, capitalizeFirstLetter };