import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap'



import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';


const Navigation = () => (
    <div>
    <Navbar bg="dark" variant="dark" expand="md">
    <Navbar.Brand as={Link} to={ROUTES.LANDING}>
        <img src={process.env.PUBLIC_URL + '/Logo_No_Text.png'} width={70}></img>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
    <AuthUserContext.Consumer>
        {authUser =>
            authUser ? <NavigationAuth /> : <NavigationNonAuth />
        }
    </AuthUserContext.Consumer>
    </Navbar.Collapse>
    </Navbar>
    </div>
);

const NavigationAuth = () => (
    <Nav className="mr-auto">
        <Nav.Link as={Link} to={ROUTES.MAP}>Create Map</Nav.Link>
        {/* <Nav.Link as={Link} to={ROUTES.ADMIN}>Admin</Nav.Link> */}

        <SignOutButton />
    </Nav>
    );

const NavigationNonAuth = () => (
    <Nav className="mr-auto">
        <Nav.Link as={Link} to={ROUTES.SIGN_IN}>Sign In</Nav.Link>
        <Nav.Link as={Link} to={ROUTES.SIGN_UP}>Sign Up</Nav.Link>
    </Nav>
    );

export default withFirebase(Navigation);