import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';


const SignInPage = () => (
  <div>
      <SignInForm />
  </div>
);


const styles = theme => ({
  root: {
    height: '100vh',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
    .doSignInWithEmailAndPassword(email, password)
    .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.MAP);
    })
    .catch(error => {
        this.setState({ error });
    });

    event.preventDefault();
};

onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
};

render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';
    const { classes } = this.props;
  return (
    <Container style={{paddingBottom: '20px'}} component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <form className={classes.form} onSubmit={this.onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              onChange={this.onChange}
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              value={password}
              onChange={this.onChange}
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Link href={ROUTES.PASSWORD_FORGET} variant="body2">
              Forgot Password?
            </Link>
            <br/>
            <Link href={ROUTES.PASSWORD_CHANGE} variant="body2">
              Change password
            </Link>
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInvalid}
              className={classes.submit}
            >
              Sign In
            </Button>
            {error && <p style={{color: 'red'}}>{error.message}</p>}
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item>
                <Link href={ROUTES.SIGN_UP} variant="body2">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
    </Container>
    );
    }
}

SignInFormBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const SignInForm = withStyles(styles)(compose(
    withRouter,
    withFirebase,
)(SignInFormBase));

export default SignInPage;

export { SignInForm };