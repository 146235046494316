import React, { useRef, useEffect, useState } from 'react';

import { Form, ListGroup } from 'react-bootstrap';
import { Scrollbar } from "react-scrollbars-custom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

// import { Modals } from'./Modals3rdTry';
import { Settings } from './Modals/Settings';

const Sidebar = (props) => {
  const {setup, height, handlers, setupKey} = props

  const headerHeight = '10vh';
  const [settingsVisible, setSettingsVisible] = useState(false)


  const SettingsButton = () => 
    <div id="settings" className="text-right">
      <button 
      className="faBtn" 
      onClick={() => setSettingsVisible(true)} 
      style={{fontSize: '1.5em'}}>
        <FontAwesomeIcon icon={faCog} color="grey"/>
      </button>
    </div>

  
  const Header = () =>
    <div id="sidebar-header" style={{height: 'headerHeight'}}>
      <div className="h4 mt-1 font-weight-bold text-center">{setup.name}</div>
      <div className="h6 font-weight-normal text-center"><i>({setup.instrumentDisplayName})</i></div>
      <p className='text-center'><i>Progress saved automatically</i></p>
    </div>


  const NotesInputs = () => 
    <div id="notes-inputs-container" style={{height: `calc(${height} - calc(${headerHeight} * 1.5)`}}>
      <Scrollbar style={{ width: "100%" }}>
        <div className="py-4 px-0 mb-4">
          <Form>
          <Form.Group controlId="formNotes" className="p-0">
            {/* Generate Intonation input fields */}
            {setup.notes.map((note, noteIndex) => { 
              let noteName = note.note + note.octave
              if (note.fingering) {
                noteName = noteName + ' ' + note.fingering
              }
              return (
                <div key={noteIndex} className="mr-2">
                  <Form.Label>{noteName}</Form.Label>
                  <Form.Control 
                  key={noteIndex} 
                  name={noteIndex} 
                  type="number"
                  maxLength="2"
                  value={setup.notes[noteIndex].intonation} 
                  onChange={(e) => handlers.updateSetup('notes', e.target, setupKey)}/>
                  <Form.Text className="text-muted">
                    Enter intonation for note (cents)
                  </Form.Text>
                </div>
            )})}
          </Form.Group>
          </Form> 
        </div>
      </Scrollbar>
    </div>

  return(
    <div id='sidebar-container'>
      <SettingsButton />
      <Header />
      <NotesInputs />
      <Settings 

      handlers={handlers}
      
      visible={settingsVisible}
      setVisible={setSettingsVisible}

      defaultSetupName = {`My Setup ${props.userSetups.length + 1}`}
      userSetups={props.userSetups}
      currentUser={props.currentUser}
      
      setup={props.setup}
      setupKey={props.setupKey}
      />
      
    </div>
  )
}

export { Sidebar };