import React from 'react';

import { Container, Row, Col, Jumbotron, Button, Alert } from 'react-bootstrap';

import * as ROUTES from '../../constants/routes';
import { MapInterface } from '../Map';
import { AuthUserContext } from '../Session';


const TopCard = (props) => {
  const {title, body} = props;
  return (
    <Col className="mb-4">
      <Alert variant="secondary" className="h-100">
        <h2>{title}</h2>
        <h5>{body}</h5>
      </Alert>
    </Col>
  )
}

const SignUpCard = () => (
    <Col>
      <Alert variant="light" className="h-100 text-center" style={{color: "black"}}>
        <h2>Start playing in tune!</h2>
        <h5>
          <div className="mt-3 mb-2"><Button variant="primary" href={ROUTES.SIGN_UP}>Sign Up</Button></div>
          <div>or <a href={ROUTES.SIGN_IN}>login</a> with an existing account</div>
        </h5>
      </Alert>
    </Col>
)


const LandingPage = () => {
  return (
    <AuthUserContext.Consumer>
    {authUser => (
      <Container fluid className="my-2">
      <header className="text-center">
        <img id="logo" src={process.env.PUBLIC_URL + '/Logo.png'} alt="Intonation Map Creator"></img>
      </header>
      <main>
        <Row className="mt-4">
          <TopCard 
          title="Visualize"
          body="your intonation on your instrument through simple, easy to use graphs."
          />
          {!authUser &&
          <SignUpCard />
          }
          <TopCard 
          title="Understand"
          body="your tendencies throughout your instrument through AI-powered anaylsis.."
          />
        </Row>
        <hr/>
        <Row>
          <Col md={12}>
            <h3 className="text-center font-weight-bold">
              See it in action
            </h3>
            <h5 className="text-center mt-1">
              Interact with the graph below, which contains sample intonation for a clarinet.
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <MapInterface 
            height={'60vh'} 
            showInputs={false} 
            loadUserData={false} 
            defaultSetup={require('../../Instruments/gg_clarinet.json')}
            />
          </Col>
      </Row>
      </main>
      </Container>
    )} 
  </AuthUserContext.Consumer>
)}

export default LandingPage;