import React from 'react';

import { MapInterface} from './MapInterface';
import { withAuthorization, AuthUserContext } from '../Session';

const MapPage = () => (
  <AuthUserContext.Consumer>
      {authUser => (
        <div className="p-2">
          <MapInterface 
          height={'80vh'} 
          loadUserData={true} 
          defaultSetup={require('../../Instruments/clarinet.json')}
          currentUser={authUser}
          />
        </div>
      )}
    </AuthUserContext.Consumer>
  )


export { MapInterface };

const condition = authUser => !!authUser;
export default withAuthorization(condition)(MapPage);