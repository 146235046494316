import React, {Component} from 'react';
import { ScatterChart, Scatter, CartesianGrid, Tooltip,
  XAxis, YAxis, ZAxis, Text, Label, ResponsiveContainer, ReferenceLine} from 'recharts';


const CustomTooltip = ({ active, payload, label }) => {
  
  let tooltipStyle = {
    backgroundColor: "white",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderWidth: "thin", 
    borderRadius: "10px",
    padding: "5px"
  
  }
  
  if (active) {
    const pointInfo = payload[0].payload;
    const fingeringPostfix = pointInfo.fingering ? ' - ' + pointInfo.fingering : ''
    return (
      <div className="custom-tooltip" style={tooltipStyle}>
        <p className="label"><b>Note</b> : {pointInfo.note}{pointInfo.octave}{fingeringPostfix}</p>
        <p className="label"><b>Intonation</b> : {pointInfo.intonation}</p>
      </div>
    );
  }

  return null;
};

//////////////////////////////////////////////////////

class IntonationMap extends Component {

  constructor(props) {
    super(props)
    this.state = {
      notes: []
    };
  }

  componentDidMount() {
    const renderHeight = document.getElementById('map-container').clientHeight;
    this.setState({
      renderHeight: renderHeight,
      notes: this.props.setup.notes
    })
  }

  shouldComponentUpdate(nextProps){
    return nextProps.setup.notes !== this.state.notes || nextProps.showAvgs !== this.props.showAvgs
  }

  componentDidUpdate(props) {
      this.setState({
        notes: props.setup.notes
      })
  } 
  


  render () {
    let notes = this.state.notes.slice();

    // register intonation averages
    const avgLines = []
    let lastNumNotes = 0
    Object.keys(this.props.setup.colorMap).forEach((register, i) => {
      let avgIntonation = 0
      let numNotes = 0
      notes.forEach(note => {
        if (note.intonation !== "" && note.register === register) {
          numNotes += 1
          avgIntonation += parseFloat(note.intonation);
        }
      })

      avgIntonation /= numNotes
      avgLines.push(
        <ReferenceLine 
        key={i} 
        segment={[{x: lastNumNotes, y: avgIntonation}, {x: lastNumNotes + numNotes, y: avgIntonation}]}
        
        label={register} 
        stroke={this.props.setup.colorMap[register]} 
        strokeDasharray="3 3" />
      )

      lastNumNotes = numNotes

    })

    let yAxisLabel = <Text x={0} y={this.state.renderHeight / 2 + 40} dx={30} offset={0} textAncher={'middle'} angle={-90} style={{fontWeight: 'bold'}}>Intonation (cents)</Text>;
    let xAxisLabel = <Label value="Notes (from low to high)" offset={-15} style={{fontWeight: 'bold'}} position="insideBottom" />;

    const Cs = ['C1','C2','C3','C4','C5','C6','C7','C8']

    let xAxis = null;
    let { onlyCs } = this.props;
    if (onlyCs) {
      xAxis = <XAxis dataKey="tickLabel" name="Note" label={xAxisLabel} interval={0} ticks={Cs}/>;
    } else {
      xAxis = <XAxis dataKey="tickLabel" name="Note" label={xAxisLabel} interval={0}/>;
    }

    


    return (
          <ResponsiveContainer width="100%" height="100%" id='map-container'>
            <ScatterChart width={1500} height={500} data={notes} margin={{ bottom: 25, top: 0 }}>
                <Scatter name="Note" dataKey='intonation' fill="#ff7300"/>
                {xAxis}
                <YAxis type="number" domain={[-30, 30]} dataKey="intonation" label={yAxisLabel} unit=""/>
                <ZAxis dataKey="size"/>
                {this.props.showAvgs &&
                avgLines
                }
                
                
                <Tooltip content={<CustomTooltip />} cursor={{ stroke: 'red', strokeDasharray: '3 3' }} />
                <CartesianGrid vertical={false} strokeDasharray="3 3" ></CartesianGrid>
            </ScatterChart>
          </ResponsiveContainer>
    );
  }
}

export default IntonationMap;

