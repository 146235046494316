import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Button, Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Scrollbar } from "react-scrollbars-custom";
import $ from 'jquery';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";


const Create = (props) => { 
    const {
        visible,
        setVisible,
        defaultSetupName,
        handlers
    } = props;

    const onSubmit = () => {

        // get setup values
        let newName = $('#new-setup-name').val() || defaultSetupName;
        let newInstrument = $('#new-setup-instrument').find(":selected").val();
        const newSetup = {'name': newName, 'instrument': newInstrument};
        handlers.handleSetupCreate(newSetup);
        setVisible(false);
    }

    return(
    <Modal show={visible} centered >
        <Modal.Header>
            <Modal.Title className="mx-auto font-weight-bold">Create Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form id="setup-create-form">
            <Form.Group controlId="formSetupName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" id="new-setup-name" placeholder={defaultSetupName} />
                <Form.Text className="text-muted">
                Enter name of setup
                </Form.Text>
            </Form.Group>
            <Form.Group controlId="formSetupInstrument">
                <Form.Label>Instrument</Form.Label>
                <Form.Control defaultValue="cl" as="select" id="new-setup-instrument">
                <option value="cl" >Clarinet</option>
                <option value="sax">Saxophone</option>
                </Form.Control>
                <Form.Text className="text-muted">
                What instrument is this setup for?
                </Form.Text>
            </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="danger" size="sm" onClick={() => setVisible(false)}>
            Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={() => onSubmit()}>
            Create
        </Button>
        </Modal.Footer>
    </Modal>
)
}

export { Create };