import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Button, Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Scrollbar } from "react-scrollbars-custom";
import $ from 'jquery';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { Create } from './Create'

const Settings = (props) => {

    const {
        visible,
        setVisible,
        handlers,

        // for settings
        setup,
        setupKey,
        userSetups,

        // for creating setups
        currentUser,
        defaultSetupName

    } = props;

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
            // disable any active setup buttons and enable this one
            $(`#${setupKey}`).addClass('active').siblings().removeClass('active');
        }
    });

    useEffect(() => {
        if (userSetups.length > 0) {
            const editSubmit = () => {
                const newName = $('#setup-preview-name').val();
                handlers.updateSetup('name', newName, setupKey);
            }
            const rightPanelContent = 
                <Form id="setup-preview-form">    
                    <Form.Group controlId="setupPreviewName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" id="setup-preview-name" placeholder={setup.name} />
                        <Form.Text className="text-muted">
                        Change setup name
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="setupPreviewInstrument">
                        <Form.Label>Instrument</Form.Label>
                        <Form.Control as="select" id="setup-preview-instrument" defaultValue="name" disabled>
                        <option value="name">{setup.instrumentDisplayName || "Not found"}</option>
                        </Form.Control>
                        <Form.Text className="text-muted">
                        This cannot be changed after setup is created
                        </Form.Text>
                    </Form.Group>
                    <Button variant="danger" onClick={() => handlers.handleSetupDelete(setupKey)}>
                        Delete
                    </Button>
                    <Button variant="primary" className="float-right" onClick={() => editSubmit()}>
                        Update
                    </Button>
                </Form>
            setRightPanel(rightPanelContent)
        } else {
            // toggle create if no more setups and settings already open
            if (visible) {
                setCreateVisible(true);
            }
        }
    }, [userSetups])

    const [createVisible, setCreateVisible] = useState(false) // create modal toggle
    
    const [rightPanel, setRightPanel] = useState("")


    const createSetupBtn = <button className="faBtn h5 float-right" onClick={() => setCreateVisible(true)}>Create Setup <FontAwesomeIcon icon={faPlus} color="green"/></button>

    return (
    <React.Fragment>
    <Modal show={visible} centered size="lg" animation={false}>
        <Modal.Header>
            <Modal.Title className="mx-auto font-weight-bold">Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container className="pb-3">
                <Row>
                    <Col xs={12} md={8} style={{height: '40vh'}} id="user-setups-editor">
                    <div id="editor-header">
                        <h4>My Setups {createSetupBtn}</h4>
                    </div>
                    <div id="editor-body" style={{width:"100%", height:"100%"}}>
                        <Scrollbar style={{ width: "100%" }}>
                        <ListGroup variant="primary">
                            {userSetups.map((setup, i) => 
                                <ListGroup.Item 
                                action
                                className="btn btn-primary"
                                id={setup.key} 
                                onClick={() => handlers.handleSetupChange(setup.key)}
                                href={`#${i}`}
                                >{setup.name}
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                        </Scrollbar>
                    </div>
                    </Col>
                    <Col xs={12} md={4} id="right-panel">
                        {rightPanel}
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" size="sm" onClick={() => setVisible(false)}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
    <Create 
    visible={createVisible}
    setVisible={setCreateVisible}
    defaultSetupName={defaultSetupName}
    handlers={handlers}
    />

    </React.Fragment>
    )
}

export { Settings };