import React from 'react';

import { withFirebase } from '../Firebase';
import { Link } from 'react-router-dom'
import * as ROUTES from '../../constants/routes';
import { Nav } from 'react-bootstrap'

const SignOutButton = ({ firebase }) => (
    <Nav.Link as={Link} onClick={firebase.doSignOut}>
        Sign Out
    </Nav.Link>
);

export default withFirebase(SignOutButton);