import React, {Component} from 'react';
import { BarChart, Bar, Tooltip,
  XAxis, YAxis, Text, Label, ResponsiveContainer, ReferenceLine, Cell, Legend} from 'recharts';

import { getRegisterPairs } from '../../utils';


const CustomTooltip = ({ active, payload, label }) => {
  
  let tooltipStyle = {
    backgroundColor: "white",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderWidth: "thin", 
    borderRadius: "10px",
    padding: "5px"
  
  }
  
  if (active) {
    const pointInfo = payload[0].payload;
    return (
      <div className="custom-tooltip" style={tooltipStyle}>
        <p className="label"><b>Register Spread</b>: {pointInfo.spread > 0&&'+'}{pointInfo.spread}</p>
        <hr/>
        <p>Upper Note ({pointInfo.upperName}): {pointInfo.upperIntonation - 30}</p>
        <p>Lower Note ({pointInfo.lowerName}): {pointInfo.lowerIntonation}</p>
        
        
      </div>
    );
  }

  return null;
};

//////////////////////////////////////////////////////

class RegisterMap extends Component {

  constructor(props) {
    super(props)
    this.state = {
      notes: [],
      registerPairs: [],
      show_avg: false
    };
  }

  componentDidMount() {
    const renderHeight = document.getElementById('map-container').clientHeight;
    this.setState({
      renderHeight: renderHeight,
      registerPairs: getRegisterPairs(this.props.setup).pairs,
      notes: this.props.setup.notes
    })
  }

  shouldComponentUpdate(nextProps){
    return nextProps.setup.notes !== this.state.notes
  }

  componentDidUpdate(props) {
    
      this.setState({
        registerPairs: getRegisterPairs(props.setup).pairs,
        notes: this.props.setup.notes
      })
  } 
  


  render () {
    const registerPairs = this.state.registerPairs;
    const avgSpread = getRegisterPairs(this.props.setup).avgSpread;
    const registerSize = 30;


    let yAxisLabel = <Text x={0} y={this.state.renderHeight / 2 + 40} dx={30} offset={0} textAncher={'middle'} angle={-90} style={{fontWeight: 'bold'}}>Register Spread</Text>;
    let xAxisLabel = <Label value="Register Pairs (from low to high)" offset={-15} style={{fontWeight: 'bold'}} position="insideBottom" />;


    return (
          <ResponsiveContainer width="100%" height="100%" id='map-container'>
            <BarChart
            width={1500}
            height={500}
            barCategoryGap={'10%'}
            
            data={registerPairs}
            margin={{ bottom: 25, top: 0 }}>
            <defs>
              <linearGradient id="colorPlus" x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor="red" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="red" stopOpacity={0.2}/>
              </linearGradient>
              <linearGradient id="colorZero" x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor="#00ff00" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#00ff00" stopOpacity={0.2}/>
              </linearGradient>
              <linearGradient id="colorMinus" x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor="#008080" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#008080" stopOpacity={0.2}/>
              </linearGradient>
              
            </defs>
                <XAxis type="number" dataKey="x" domain={[0, registerPairs.length + 1]} label={xAxisLabel} tick={false}>
                  <Label value={`Average Spread: ${avgSpread}`} style={{fontWeight: 'bold'}} position="insideTopLeft" />;
                </XAxis>
                <YAxis range={[-30, 60]} label={yAxisLabel} unit = "" tick={false} />
                <Bar type="monotone" dataKey="registerPair" stroke="#8884d8" >
                  {
                    registerPairs.map((pair, i) => {
                      let fill="";
                      if (pair.spread > 0){
                        fill = "red"
                      }
                      else if (pair.spread < 0){
                        fill = "blue"
                      } else {
                        fill = "green"
                      }
                      return <Cell fill={fill} />;
                    })
                  }
                </Bar>
                <Tooltip content={<CustomTooltip />} cursor={{ stroke: 'red', strokeDasharray: '3 3' }} />
                <ReferenceLine y={registerSize} stroke="grey" strokeDasharray="3 3" />
                <ReferenceLine y={0} stroke="grey" strokeDasharray="3 3" />
                
                <Legend
                verticalAlign="top" 
                layout="horizontal" 
                align="center" 
                
                // wrapperStyle={{paddingRight: "70%"}}
                payload={
                  [
                    { value: '-', type: 'rect', color:"blue" },
                    { value: 'Perfect Register', type: 'rect', color:"green" },
                    { value: '+', type: 'rect', color:"red" }
                  ]
                }/>
            </BarChart>
          </ResponsiveContainer>
    );
  }
}

export default RegisterMap;

