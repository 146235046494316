import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Jumbotron, Form } from 'react-bootstrap';
import $ from 'jquery';

import IntonationMap from './IntonationMap';
import RegisterMap from './RegisterMap';
import { Sidebar } from './Sidebar';
import { withFirebase } from '../Firebase';
import { preprocessSetup, capitalizeFirstLetter } from '../../utils';


// MapInterface is MapBase with firebase (see below)

const MapBase = (props) => {

    const {
        // data
        firebase, // from context provider
        currentUser,
        loadUserData,
        defaultSetup,

        // settings
        showInputs,
        onlyCs,
        height
    } = props;

    const sidebarRef = useRef()

    const [setup, setSetup] = useState(defaultSetup)
    const [setupKey, setSetupKey] = useState(null)
    const [userSetups, setUserSetups] = useState([])
    const [showAvgs, setShowAvgs] = useState(false)
    const [mapName, setMapName] = useState('intonation')

    const defaultMap = <IntonationMap showAvgs={showAvgs} setup={setup} onlyCs={onlyCs}/>
    const [currentMap, setMap] = useState(defaultMap)
    

    useEffect(() => {
      if (loadUserData) {
        // Load initial setup of current user
        console.log('Loading user setup...')

        firebase.user(currentUser.uid).child("setups").limitToFirst(1)
          .once('value', snapshot => {
            // create setup if none is found
            if (snapshot.val() === null) {
              console.log('No setup found, adding setup to account...')
              handleSetupCreate(defaultSetup)
            } else {
              console.log('Setup found, loading...')
              const firstSetupKey = Object.keys(snapshot.val())[0];
              const firstSetup = snapshot.val()[firstSetupKey] // get first setup by key
              setSetup(firstSetup)
              setSetupKey(firstSetupKey)

              refreshUserSetups()
            }
          })
      }
    }, []);


    //// handlers and helpers

    const handleSetupCreate = (newSetupInfo) => {
      console.log("Creating new setup...")
      const newSetupRef = firebase.user(currentUser.uid).child('setups').push()

      // load appropriate instrument data
      // TODO: put these require() statements at top of file and store in vars
      let newSetup = undefined
      switch (newSetupInfo.instrument) {
        case "cl":
          newSetup = preprocessSetup(require('../../Instruments/clarinet.json'))
          break;
        case "sax":
          newSetup = preprocessSetup(require('../../Instruments/sax.json'))
          break;
        default:
          newSetup = preprocessSetup(require('../../Instruments/clarinet.json')) // default to clarinet
          break;
      }
      newSetup.name = newSetupInfo.name

      console.log("New setup:")
      console.log(newSetup)

      // update setup in database
      newSetupRef.set(newSetup)

      // refetch user setups list
      refreshUserSetups()

      // change to the new setup
      handleSetupChange(newSetupRef.key)
    }


    const handleSetupChange = (setupKey) => {
      console.log('Changing setup...')

      // get setup based on chosen key
      firebase.user(currentUser.uid).child('setups').child(setupKey)
      .once('value', snapshot => {
        const setup = snapshot.val()
        setSetup(setup)
        setSetupKey(setupKey)
      })
    }


    const refreshUserSetups = () => {
      console.log('Refreshing user setups...')

      // get all setups under current user
      props.firebase.user(currentUser.uid).child('setups')
      .once('value', snapshot => {
        let userSetups = []
        snapshot.forEach(setup => {
          const setupKey = setup.key
          const setupData = setup.val()
          userSetups.push({
            'name': setupData.name,
            'key': setupKey,
            'instrument': setupData.instrument,
            'instrumentDisplayName': setupData.instrumentDisplayName
          })
        })
        setUserSetups(userSetups)
      })
    }


    const updateSetup = (key, value, setupKey) => {
      console.log(`Updating setup ${key}`)
      console.log(`With value ${value}`)
      console.log(setup)

      // process values if needed
      switch (key) {
        case 'notes':
          // (intonation) data is handled slightly differently since it doesn't come from the modals
            const intonation = value.value;
            const noteIndex = value.name;
            let newNotes = setup.notes.slice();
            newNotes[noteIndex].intonation = intonation;
            newNotes[noteIndex].size = 4
            value = newNotes.slice();
          break;
        default:
          // value is good as is
          break;
      }
  
      const updatedSetup = {...setup, [key]: value}

      console.log("Updated setup:")
      console.log(updatedSetup)

      firebase.user(currentUser.uid).child('setups').child(setupKey)
      .update(updatedSetup)
      .then(() => {
        setSetup(updatedSetup) // update setup with new values
        refreshUserSetups(); // in case the name changes
        console.log('Saved to database')
        return(updatedSetup);
      })
      .catch(console.log('Error saving to database'));
    }


    const handleSetupDelete = (setupKey) => {
      console.log("Deleting setup...")

      // check if there are any other setups to switch to
      if (userSetups.length > 1){

        // find other setup key to switch to
        let newSetupKey = null;
        let pastSetupIndex = null;
        userSetups.forEach((setup, i) => {
          if (setup.key == setupKey) {
            pastSetupIndex = i
          }
        })
        const prevSetupInList = userSetups[pastSetupIndex-1]
        const nextSetupInList = userSetups[pastSetupIndex+1]
        if (prevSetupInList !== undefined) {
          // next setup
          newSetupKey = prevSetupInList.key
        } else if (nextSetupInList !== undefined) {
          // prev setup
          newSetupKey = nextSetupInList.key
        }

        // change to other setup
        handleSetupChange(newSetupKey)
      
      }
      // delete setup by setting reference to null
      firebase.user(currentUser.uid).child('setups').child(setupKey).set(null)

      // refresh setups list
      refreshUserSetups()

      // } else {
      //   // new setup
      //   console.log("Zero setups not allowed. Showing create setup modal...")

      //   // delete setup by setting reference to null
      //   firebase.user(currentUser.uid).child('setups').child(setupKey).set(null)

      //   // refresh setups list so the default name is generated correctly
      //   refreshUserSetups();

      //   // create new setup
      //   sidebarRef.current.toggleCreateSetupModal();
      // }
    }


    const handleMapChange = (newMap) => {
      console.log("Changing to " + newMap + " map");
      console.log("With setup ", setup)
        switch (newMap) {
        case 'intonation':
            setMapName(newMap)
            setMap(<IntonationMap setup={setup} showAvgs={showAvgs}  onlyCs={onlyCs}/>)
            break;
        case 'register':
            setMapName(newMap)
            setMap(<RegisterMap setup={setup} />)
            break;
        default:
          throw new Error(`Invalid map type "${newMap}"`)
        }
    }


    //// rendering


    const MapButtons = () => {
      const maps = ['intonation'];
  
      // add appropriate maps based on current setup
      if(setup.hasOwnProperty('registerPairs')) maps.push('register');
  
      // display map radio buttons if there is more than one option
      if(maps.length > 1){
        return(
        <Form>
          <h5>Map type</h5>
          <Form.Group controlId="mapType">
            {maps.map((idx_mapName, i) => {
              return(<Form.Check
                inline
                value={idx_mapName}
                type="radio"
                aria-label="radio 1"
                label={capitalizeFirstLetter(idx_mapName)}
                onChange={(e) => handleMapChange(e.target.value.toLowerCase())}
                checked={mapName === idx_mapName}
              />)
            })}
          </Form.Group>
        </Form>
        );
      } else {
        return null
      }
    }


    const MapHeader = () => {
      return(
      <Row style={{width: '100%', height: '10vh'}}>
        {mapName == "intonation" &&
        <Form className="ml-3">
          <Form.Check 
          type="checkbox" 
          label="Show Averages" 
          onChange={(e) => setShowAvgs(e.target.checked)}
          checked={showAvgs}/>
        </Form>
        }
          <div className="mx-auto font-weight-bold h3">
            {`${capitalizeFirstLetter(mapName)} Map`}
            </div>
          <div className="text-right">
            {<MapButtons />}
          </div>
      </Row>
    )};


    const getMap = () => {
      switch (mapName) {
        case 'intonation':
          return <IntonationMap showAvgs={showAvgs} setup={setup} onlyCs={onlyCs}/>
        case 'register':
          return <RegisterMap setup={setup} />
        default:
          break;
      }
    }

    const handlers = {
      handleSetupChange,
      handleSetupCreate,
      handleSetupDelete,
      updateSetup
    }

    return (    
    <Row className="justify-content-md-center" style={{height: "100%"}}>
      <Col lg={showInputs ? 9 : 12} className="text-center" id="map-container">
        <Jumbotron className="px-2 py-2 mr-2 mb-1">              
          <MapHeader/>
          <Row className="pt-4" style={{width: '100%', height: height}}>
            {getMap()} 
          </Row>
        </Jumbotron>
      </Col>

      {/* Notes Form */}
      {showInputs &&
      <Col lg={3} style={{height: height}}>
        <Jumbotron className="px-2 py-2 mr-2 mb-1">
          <Sidebar 
          ref={sidebarRef}

          handlers={handlers}

          height={height} 
          
          setup={setup} 
          setupKey={setupKey}
          userSetups={userSetups}
          currentUser={currentUser}
          
          />
        </Jumbotron>
      </Col>
      }
    </Row>
    )
}


MapBase.propTypes = {
  showInputs: PropTypes.bool,
  loadUserData: PropTypes.bool,
  onlyCs: PropTypes.bool,
  height: PropTypes.number,
  defaultSetup: PropTypes.object,
}

MapBase.defaultProps = {
  showInputs: true,
  loadUserData: false,
  onlyCs: true,
  height: 600,
  defaultSetup: require('../../Instruments/clarinet.json') // default loadout
}


const MapInterface = withFirebase(MapBase);


export { MapInterface };